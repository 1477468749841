'use client';

import { FC, PropsWithChildren, createContext, useCallback, useState } from 'react';
import { getBuyerHasListingRecommendations } from '@/app/(authenticated)/(marketplace)/inbox/actions';

export const InboxButtonContext = createContext<{
  hasUnreadMessages: boolean;
  refresh: () => void;
}>({
  hasUnreadMessages: false,
  refresh: () => {},
});

export const InboxButtonProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const refresh = useCallback(async () => {
    const hasUnread = await getBuyerHasListingRecommendations();
    setHasUnreadMessages(hasUnread);
  }, []);

  return (
    <InboxButtonContext.Provider value={{ refresh, hasUnreadMessages }}>
      {children}
    </InboxButtonContext.Provider>
  );
};
