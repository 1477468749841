import React, { ReactNode, forwardRef } from 'react';
import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';

export const MAX_CONTENT_WIDTH = '1440px';

/**
 * This component is used to wrap content that should be centered on the page, up to a maximum width.
 *
 * This does NOT ensure that the content has the specified margin, so you may need to add margin to the content.
 *
 * It also ensures the outer width is 1100%
 */
export const ConStrainer = forwardRef(function ConStrainer(
  {
    outerContainerProps,
    innerContainerProps,
    children,
  }: {
    outerContainerProps?: BoxProps;
    innerContainerProps?: BoxProps;
    children: ReactNode;
  },
  ref,
) {
  return (
    // Leave outer container props available to set full-width background color, etc
    <Box width={'100%'} {...outerContainerProps} ref={ref}>
      {/* Set to max content width, use margin x auto to center on screen */}
      <Box maxWidth={MAX_CONTENT_WIDTH} marginX={'auto'} {...innerContainerProps}>
        {children}
      </Box>
    </Box>
  );
});
