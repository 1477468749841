'use client';

import { FC, PropsWithChildren, createContext, useEffect, useState } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';

export const SearchBoxContext = createContext<{
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  search: (term: string) => void;
}>({
  searchTerm: '',
  setSearchTerm: () => {},
  search: () => {},
});

export const SearchBoxProvider: FC<PropsWithChildren<{ productsIndexName: string }>> = ({
  children,
  productsIndexName,
}) => {
  const params = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(params.get(`${productsIndexName}[query]`) || '');

  const router = useRouter();

  const search = (term: string) => {
    router.push(`/search?${productsIndexName}%5Bquery%5D=${term}`);
  };

  const queryValueFromParams = params.get(`${productsIndexName}[query]`);

  // When navigating away from the search page, we want to clear the search term.
  // If the user navigates back to the search page, we want to restore the search term.
  useEffect(() => {
    setSearchTerm(queryValueFromParams ?? '');
  }, [queryValueFromParams]);

  return (
    <SearchBoxContext.Provider value={{ searchTerm, setSearchTerm, search }}>
      {children}
    </SearchBoxContext.Provider>
  );
};
