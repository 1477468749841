import capitalize from 'capitalize';
import { BuyerSummary } from '../buyers/buyerSummary.model';
import { ReferrerSummary } from '../referrers/referrerSummary.model';
import { SellerSummary } from '../sellers/sellerSummary.model';

export interface UserSummary {
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  phone?: string;
  buyer?: BuyerSummary;
  seller?: SellerSummary;
  referrer?: ReferrerSummary;
  intercomHash: string;
}

export const getFullUserName = (user: UserSummary) =>
  `${capitalize.words(user.firstName)} ${capitalize.words(user.lastName)}`;
