import { FC } from 'react';
import Typography from '@mui/material/Typography';
import MenuItem, { type MenuItemProps } from '@/components/MenuItem';

const UserMenuItem: FC<MenuItemProps & { title: string }> = ({ title, ...props }) => {
  return (
    <MenuItem {...props}>
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

export default UserMenuItem;
