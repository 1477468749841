'use client';

import { FC, use } from 'react';
import { Spacing } from '@ghosts-inc/design-system/constants';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { usePathname } from 'next/navigation';
import { ArrearsContext } from '@/components/ArrearsBanner/ArrearsContext';
import Link from '@/components/Link';
import Collapser from './Collapser';

const PreferencesBanner: FC = () => {
  const isBelowTablet = useMediaQuery((th: Theme) => th.breakpoints.down('tablet'));

  const { showArrearsBanner } = use(ArrearsContext);

  const path = usePathname();

  const showPreferencesBanner = path === '/';

  if (!showPreferencesBanner || showArrearsBanner) return null;

  return (
    <Collapser>
      <Stack
        alignItems="center"
        bgcolor={(th) => th.palette.common.black}
        color={(th) => th.palette.common.white}
        py={Spacing.base}
      >
        <Typography color="typographyDarkInverted">
          {isBelowTablet ? (
            <>Edit your</>
          ) : (
            <>Customize recommendations by Brand, Category, and more</>
          )}{' '}
          <Link color="typographyBrandPrimaryLight" href="/preferences">
            Preferences
          </Link>{' '}
          ✨
        </Typography>
      </Stack>
    </Collapser>
  );
};

export default PreferencesBanner;
