'use client';

import { use, useState } from 'react';
import { Spacing } from '@ghosts-inc/design-system/constants';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { usePathname } from 'next/navigation';
import { ArrearsContext } from '@/components/ArrearsBanner/ArrearsContext';
import Link from '@/components/Link';
import { LineSheetTutorialVideo } from '../../../listings/[id]/upload-offer/_components/Instructions/LineSheetTutorialVideo';
import Collapser from '../Collapser';

type Props = {
  lineSheetUploadTutorialUrl: string | null;
};

export const UploadLineSheetOfferBanner = ({ lineSheetUploadTutorialUrl }: Props) => {
  const [showVideo, setShowVideo] = useState(false);
  const { showArrearsBanner } = use(ArrearsContext);

  const path = usePathname();

  const showUploadOfferBanner = path === '/';

  if (!showUploadOfferBanner || showArrearsBanner || !lineSheetUploadTutorialUrl) return null;

  return (
    <Collapser>
      <Stack
        alignItems="center"
        bgcolor={(th) => th.palette.common.black}
        color={(th) => th.palette.common.white}
        direction="row"
        gap={1}
        justifyContent="center"
        py={Spacing.small12}
      >
        <Typography color="typographyDarkInverted" display={{ mobile: 'none', tablet: 'block' }}>
          Learn how to Upload Offers from Line Sheets directly in Ghost.
        </Typography>
        <Typography color="typographyDarkInverted" display={{ mobile: 'block', tablet: 'none' }}>
          Upload Offers from Line Sheets.
        </Typography>
        <Box display={{ mobile: 'none', tablet: 'inline-block' }}>
          <Link color="typographyBrandPrimaryLight" href="" onClick={() => setShowVideo(true)}>
            Watch Our Overview
          </Link>{' '}
          ⚡️
        </Box>
        <Link
          color="typographyBrandPrimaryLight"
          href=""
          display={{ mobile: 'inline-block', tablet: 'none' }}
          onClick={() => setShowVideo(true)}
        >
          Watch Overview
        </Link>
        <LineSheetTutorialVideo
          open={showVideo}
          onClose={() => setShowVideo(false)}
          url={lineSheetUploadTutorialUrl}
        />
      </Stack>
    </Collapser>
  );
};
