'use client';

import { FC, useCallback, useEffect, useState } from 'react';
import { Radius, Spacing } from '@ghosts-inc/design-system/constants';
import { DotFilled } from '@ghosts-inc/design-system/icons';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Roboto_Mono } from 'next/font/google';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { AnalyticsEventNames } from '@/analytics/Event.types';
import { analytics } from '@/analytics/segment';
import banner from '@/assets/ghost_coin_banner.png';
import { useGhostStore } from '@/legacy/store';
import { DAY_MS, HOUR_MS, MINUTE_MS } from '@/lib/constants';
import Section from './_components/Section';
import { LivestreamSummary } from './actions';

const ROBOTO = Roboto_Mono({
  subsets: ['latin'],
  display: 'swap',
});

type Liveliness = 'upcoming' | 'live' | 'completed';

const calculateLiveliness = (start: Date, duration: number): Liveliness => {
  const now = new Date();
  const end = new Date(start.getTime() + duration * MINUTE_MS);
  const timeDiff = start.getTime() - now.getTime();

  if (timeDiff > 0) {
    return 'upcoming';
  } else if (now < end) {
    return 'live';
  } else {
    return 'completed';
  }
};

export const GhostLivestreamBanner: FC<{
  isForYouEnabled: boolean;
  livestream: LivestreamSummary;
}> = ({ isForYouEnabled, livestream }) => {
  const pathname = usePathname();
  const start = new Date(livestream.startTime);

  if (isNaN(start.getTime())) {
    console.warn('Invalid startTime:', livestream.startTime);
    return null;
  }

  const liveliness = calculateLiveliness(start, livestream.duration);

  const shouldShowBanner = pathname === '/' && liveliness !== 'completed';
  if (!shouldShowBanner) return null;

  return (
    <Banner
      isForYouEnabled={isForYouEnabled}
      livestream={livestream}
      initialLiveliness={liveliness}
    />
  );
};

const Banner: FC<{
  isForYouEnabled: boolean;
  livestream: LivestreamSummary;
  initialLiveliness: Liveliness;
}> = ({
  isForYouEnabled,
  livestream: { id, duration, startTime, topic, registration_url },
  initialLiveliness,
}) => {
  const [countdown, setCountdown] = useState<string>('');
  const [liveliness, setLiveliness] = useState<Liveliness>(initialLiveliness);

  const currentUser = useGhostStore((state) => state.user);

  const calculateCountdown = useCallback(() => {
    const now = new Date();
    const start = new Date(startTime);
    const diff = start.getTime() - now.getTime();
    const days = Math.floor(diff / DAY_MS);
    const hours = Math.floor((diff % DAY_MS) / HOUR_MS);
    const minutes = Math.floor((diff % HOUR_MS) / MINUTE_MS);
    const seconds = Math.floor((diff % MINUTE_MS) / 1000);
    setCountdown(`STARTS IN ${days}D ${hours}H ${minutes}M ${seconds}S`);

    setLiveliness(calculateLiveliness(start, duration));
  }, [startTime, duration]);

  const onBannerClick = () => {
    analytics.track(AnalyticsEventNames.BUYER_CLICKED_LIVESTREAM_BANNER, {
      user_id: currentUser?.id,
      livestream_id: id,
      livestream_start_time: startTime,
      livestream_topic: topic,
      livestream_liveliness: liveliness,
    });
  };

  useEffect(() => {
    calculateCountdown();

    if (liveliness !== 'completed') {
      const timer = setInterval(calculateCountdown, 1000);

      return () => clearInterval(timer);
    }
  }, [liveliness, calculateCountdown]);

  const isLive = liveliness === 'live';

  if (liveliness === 'completed') return null;

  return (
    <Section background={isForYouEnabled ? 'light' : 'transparent'}>
      <Stack
        px={6}
        py={Spacing.xxlarge}
        mb={{
          mobile: -Spacing.small,
          smallTablet: -Spacing.large,
          tablet: -Spacing.large,
          desktop: -Spacing.xxlarge,
        }}
        // mb is negative to account for the space below the banner
        // (from the contents below) causing the banner to appear spatially lopsided
        borderRadius={Radius.base}
        flexDirection={{ mobile: 'column', tablet: 'row' }}
        gap={Spacing.large}
        justifyContent="space-between"
        sx={{
          backgroundImage: `url(${banner.src})`,
          backgroundSize: 'cover',
          backgroundPositionY: 'top',
          backgroundPositionX: 'center',
          textAlign: { mobile: 'center', tablet: 'left' },
        }}
      >
        <Stack gap={Spacing.small}>
          <Typography variant="h1" color="typographyDarkInverted">
            {topic}
          </Typography>
          <Typography variant="bodyLargeLight" color="typographyDarkInverted">
            {isLive
              ? 'Check out the hottest deals at Ghost right now'
              : 'Register to see the hottest deals on Ghost'}
          </Typography>
        </Stack>
        <Stack>
          <Stack
            gap={Spacing.large}
            alignItems={{ mobile: 'center', tablet: 'flex-end' }}
            color={(theme) => theme.palette.typographyDarkInverted.main}
            fontSize="16px"
            justifyContent={{ mobile: 'center', tablet: 'flex-start' }}
            className={ROBOTO.className}
            sx={{ textWrap: 'nowrap' }}
          >
            {isLive ? (
              <Stack direction="row" gap={Spacing.small}>
                <DotFilled
                  width="14px"
                  sx={(theme) => ({ color: theme.palette.brandSecondaryFill.default })}
                />
                <Box>STREAMING LIVE NOW</Box>
              </Stack>
            ) : (
              countdown
            )}
            <Link href={registration_url} rel="noopener noreferrer" target="_blank">
              <Button variant="outlined" onClick={onBannerClick}>
                {isLive ? 'Join Now' : 'Register Now'}
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Stack>
    </Section>
  );
};
