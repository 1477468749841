'use client';

import { FC, useEffect, useState } from 'react';
import { CaretDown, DotFilled } from '@ghosts-inc/design-system/icons';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AnalyticsEventNames } from '@/analytics/Event.types';
import { analytics } from '@/analytics/segment';
import { DisplayedMyDealCategory } from '@/components/Deals/types';
import MenuItem, { MenuItemProps } from '@/components/MenuItem';
import { getActionRequiredCountForBuyer } from '../actions';
import { BuyerDealsList, DEALS_PAGE_LIST, DEFAULT_DEALS_COUNT, MyDealMenuForBuyer } from '../types';

const DealMenuItem: FC<
  Omit<MenuItemProps, 'href'> & {
    // require onClick
    onClick: () => void;
    title: string;
    count: number;
    status: MyDealMenuForBuyer;
  }
> = ({ title, status, count, onClick }) => {
  const menuHref = status === DisplayedMyDealCategory.ALL_DEALS ? `/deals` : `/deals/${status}`;

  const handleMenuItemClick = () => {
    analytics.track(AnalyticsEventNames.BUYER_CLICKED_MY_DEALS_MENU_ITEM, { menuItem: title });
    onClick();
  };

  return (
    <MenuItem href={menuHref} onClick={handleMenuItemClick}>
      <Stack direction="row" width={220} justifyContent="space-between" alignItems="center">
        <Typography>{title}</Typography>

        {count > 0 && (
          <Chip variant="filled" label={count} size="xs" sx={{ borderRadius: '4px' }} />
        )}
      </Stack>
    </MenuItem>
  );
};

const MyDealsMenu: FC<{ anchorSide?: 'left' | 'right' }> = ({ anchorSide = 'right' }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [buyerDealsList, setBuyerDealsList] = useState<BuyerDealsList>(DEFAULT_DEALS_COUNT);
  const open = Boolean(anchorEl);

  const refresh = async () => {
    const updatedList = await getActionRequiredCountForBuyer();
    setBuyerDealsList(updatedList);
  };

  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    refresh();
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    refresh();
  }, []);

  const actionRequired = buyerDealsList[DisplayedMyDealCategory.ALL_DEALS] > 0;

  return (
    <>
      <Button
        variant="text"
        size="headerLink"
        startIcon={
          actionRequired && (
            <DotFilled data-testid="needs-review-dot" color="primary" sx={{ width: 8 }} />
          )
        }
        endIcon={<CaretDown />}
        onClick={showMenu}
      >
        My Deals
      </Button>
      <Menu
        data-testid="my-deals-menu"
        open={open}
        onClose={closeMenu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: anchorSide,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: anchorSide,
        }}
      >
        {Object.entries(DEALS_PAGE_LIST).map(([offerState, offerStateLabel]) => {
          const count = buyerDealsList[offerState as MyDealMenuForBuyer];

          return (
            <DealMenuItem
              count={count}
              key={offerState}
              status={offerState as MyDealMenuForBuyer}
              title={offerStateLabel}
              onClick={closeMenu}
            />
          );
        })}
      </Menu>
    </>
  );
};

export default MyDealsMenu;
