'use client';

import { PropsWithChildren, createContext, useCallback, useEffect, useState } from 'react';
import getBuyerArrears from '@/app/(authenticated)/(marketplace)/lib/getBuyerArrears';
import { OverdueStatus } from './types';

type ArrearsContextType = {
  dealCycleIds: number[];
  showArrearsBanner: boolean;
  overdueStatus: OverdueStatus;
};

export const ArrearsContext = createContext<ArrearsContextType>({
  dealCycleIds: [],
  showArrearsBanner: false,
  overdueStatus: OverdueStatus.WARNING,
});

type ArrearsContextProviderProps = {
  buyerId?: number;
};

export function ArrearsContextProvider({
  buyerId,
  children,
}: PropsWithChildren<ArrearsContextProviderProps>) {
  const [showBanner, setShowBanner] = useState(false);
  const [overdueStatus, setOverdueStatus] = useState<OverdueStatus>(
    OverdueStatus.NO_OVERDUE_PAYMENTS,
  );
  const [dealCycleIds, setDealCycleIds] = useState<number[]>([]);

  const getBannerSetting = useCallback(async () => {
    const buyerArrears = await getBuyerArrears(buyerId);

    const showBanner = buyerArrears.overdueStatus !== OverdueStatus.NO_OVERDUE_PAYMENTS;

    setShowBanner(showBanner);
    setOverdueStatus(buyerArrears.overdueStatus);

    if (buyerArrears.overdueStatus !== OverdueStatus.NO_OVERDUE_PAYMENTS) {
      setDealCycleIds(buyerArrears.dealCycleIds);
    }
  }, [buyerId]);

  useEffect(() => {
    getBannerSetting();
  }, [getBannerSetting]);

  return (
    <ArrearsContext.Provider value={{ dealCycleIds, overdueStatus, showArrearsBanner: showBanner }}>
      {children}
    </ArrearsContext.Provider>
  );
}
