'use client';

import { FC, MouseEventHandler, useState } from 'react';
import { useClerk } from '@clerk/nextjs';
import { ProfileButton } from '@ghosts-inc/design-system/components';
import Menu from '@mui/material/Menu';
import UserMenuItem from '@/components/UserMenu/UserMenuItem';
import { ReferrerStates } from '@/legacy/referrers/referrerSummary.model';
import { SellerStates } from '@/legacy/sellers/sellerSummary.model';
import { useGhostStore } from '@/legacy/store';
import buildInitials from '@/lib/buildInitials';
import getRoles from '@/lib/clerk/getRoles';
import useLogOut from '@/lib/clerk/useLogOut';

const UserMenuContent: FC<{
  initials: string;
  isReferrer: boolean;
  isSeller: boolean;
  clerkEnabled?: boolean;
}> = ({ initials, isReferrer, isSeller, clerkEnabled = false }) => {
  const { logOut } = useLogOut(clerkEnabled);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const showMenu: MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ProfileButton data-testid="user-menu-button" onClick={showMenu} selected={open}>
        {initials}
      </ProfileButton>
      <Menu
        data-testid="user-menu-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={closeMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <UserMenuItem href="/preferences" title="Preferences" onClick={closeMenu} />
        {isSeller && (
          <UserMenuItem
            href="/seller"
            title="Switch to Seller"
            target="_blank"
            onClick={closeMenu}
          />
        )}
        {isReferrer && (
          <UserMenuItem
            href="/referrer"
            title="Switch to Referrer"
            target="_blank"
            onClick={closeMenu}
          />
        )}
        <UserMenuItem onClick={logOut} title="Sign Out" />
      </Menu>
    </>
  );
};

const ClerkUserMenu = () => {
  const { user } = useClerk();
  if (!user) return null;

  const roles = getRoles(user);

  const isSeller = roles.includes('seller');
  const isReferrer = roles.includes('referrer');

  return (
    <UserMenuContent
      clerkEnabled
      initials={buildInitials(user)}
      isSeller={isSeller}
      isReferrer={isReferrer}
    />
  );
};

const LegacyUserMenu = () => {
  const { user } = useGhostStore();
  if (!user) return null;

  const isSeller = user.seller?.state === SellerStates.ACTIVATED;
  const isReferrer = user.referrer?.state === ReferrerStates.ACTIVATED;

  return (
    <UserMenuContent initials={buildInitials(user)} isSeller={isSeller} isReferrer={isReferrer} />
  );
};

const UserMenu: FC<{ clerkEnabled: boolean }> = ({ clerkEnabled }) => {
  return clerkEnabled ? <ClerkUserMenu /> : <LegacyUserMenu />;
};

export default UserMenu;
