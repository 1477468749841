import { DisplayedDealStatusForBuyer } from '@/app/(authenticated)/(marketplace)/offers/types';
import { DisplayedMyDealCategory } from '@/components/Deals/types';

export type MyDealMenuForBuyer = Exclude<DisplayedMyDealCategory, DisplayedMyDealCategory.ARCHIVED>;
export type BuyerDealsList = Record<MyDealMenuForBuyer, number>;

export const DEALS_PAGE_LIST: Record<MyDealMenuForBuyer, string> = {
  [DisplayedMyDealCategory.ALL_DEALS]: 'All Deals',
  [DisplayedMyDealCategory.OFFERS]: 'Offers',
  [DisplayedMyDealCategory.ORDERS]: 'Orders',
};

export const DEFAULT_DEALS_COUNT: BuyerDealsList = {
  [DisplayedMyDealCategory.ALL_DEALS]: 0,
  [DisplayedMyDealCategory.OFFERS]: 0,
  [DisplayedMyDealCategory.ORDERS]: 0,
};

export type BuyerOffersList = Record<DisplayedDealStatusForBuyer, number>;

export const DEFAULT_OFFERS_COUNT: BuyerOffersList = {
  [DisplayedDealStatusForBuyer.NEEDS_REVIEW]: 0,
  [DisplayedDealStatusForBuyer.DRAFT]: 0,
  [DisplayedDealStatusForBuyer.PENDING]: 0,
  [DisplayedDealStatusForBuyer.COMPLETED]: 0,
  [DisplayedDealStatusForBuyer.ARCHIVED]: 0,
};
