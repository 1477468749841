import { PropsWithChildren } from 'react';
import { StackProps } from '@mui/material';
import Stack from '@mui/material/Stack';

export default function Banner({
  children,
  ...stackProps
}: PropsWithChildren<StackProps>): JSX.Element {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      minHeight={50}
      {...stackProps}
    >
      {children}
    </Stack>
  );
}
