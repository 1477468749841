'use client';

import { useEffect } from 'react';
import { BuyerPreferences } from '@/app/(authenticated)/(marketplace)/_components/types';
import { useGhostStore } from '@/legacy/store';

export const BuyerPreferencesInit = ({
  buyerPreferences: fetchedBuyerPreferences,
}: {
  buyerPreferences: BuyerPreferences;
}) => {
  const { buyerPreferences: storedBuyerPreferences, setBuyerPreferences } = useGhostStore(
    (store) => store,
  );

  useEffect(() => {
    if (fetchedBuyerPreferences && !storedBuyerPreferences) {
      setBuyerPreferences(fetchedBuyerPreferences);
    }
  }, [fetchedBuyerPreferences, setBuyerPreferences, storedBuyerPreferences]);

  return null;
};
