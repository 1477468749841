export enum OverdueStatus {
  ERROR = 'error',
  WARNING = 'warning',
  NO_OVERDUE_PAYMENTS = 'no_overdue_payments',
}

type BuyerWithoutArrearsDto = {
  overdueStatus: OverdueStatus.NO_OVERDUE_PAYMENTS;
};

type BuyerWithArrearsDto = {
  overdueStatus: OverdueStatus.ERROR | OverdueStatus.WARNING;
  dealCycleIds: number[];
};

export type BuyerArrearsDto = BuyerWithoutArrearsDto | BuyerWithArrearsDto;
