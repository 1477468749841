'use client';

import { FC } from 'react';
import { Sizes, Spacing } from '@ghosts-inc/design-system/constants';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import Collapser from './Collapser';
import { CATEGORIES } from './constants';

const CategoryList: FC<{
  isStockXSectionEnabled: boolean;
  forceTallCategoriesOnDesktop: boolean;
}> = ({ isStockXSectionEnabled, forceTallCategoriesOnDesktop: forceTallCategories }) => {
  const iconSize = forceTallCategories ? Sizes.small : Sizes.xsmall;

  if (isStockXSectionEnabled) {
    return (
      <>
        {CATEGORIES.map(({ label, href, icon }) => (
          <Link href={href} key={label}>
            <Stack
              width={{ mobile: 'unset', desktop: forceTallCategories ? 80 : 'unset' }}
              direction={{
                tablet: 'column',
                desktop: forceTallCategories ? 'column' : 'row',
              }}
              py={Spacing.small}
              alignItems="center"
              gap={forceTallCategories ? Spacing.xxsmall : Spacing.xsmall}
              sx={(theme) => ({
                '&:hover': {
                  color: theme.palette.typographyDarkSecondary.main,
                  '.MuiTypography-root': {
                    color: theme.palette.typographyDarkSecondary.main,
                  },
                },
              })}
            >
              <Box display={{ mobile: 'none', desktop: 'block' }}>
                {icon({
                  sx: {
                    width: iconSize,
                    height: iconSize,
                  },
                })}
              </Box>
              <Typography>{label}</Typography>
            </Stack>
          </Link>
        ))}
      </>
    );
  }

  return (
    <>
      {CATEGORIES.filter(({ label }) => label !== 'StockX').map(({ label, href, icon }) => (
        <Link href={href} key={label}>
          <Stack
            width={{ smallTablet: 80, desktop: 'unset' }}
            direction={{
              tablet: 'column',
              desktop: forceTallCategories ? 'column' : 'row',
            }}
            py={Spacing.small}
            alignItems="center"
            gap={forceTallCategories ? Spacing.xxsmall : Spacing.xsmall}
            sx={(theme) => ({
              '&:hover': {
                color: theme.palette.typographyDarkSecondary.main,
                '.MuiTypography-root': {
                  color: theme.palette.typographyDarkSecondary.main,
                },
              },
            })}
          >
            {icon({
              sx: {
                width: iconSize,
                height: iconSize,
              },
            })}

            <Typography>{label}</Typography>
          </Stack>
        </Link>
      ))}
    </>
  );
};

const Categories: FC<{ isStockXSectionEnabled: boolean }> = ({ isStockXSectionEnabled }) => {
  const pathname = usePathname();

  // we only show categories on certain pages
  const showCategories =
    pathname === '/' ||
    pathname.startsWith('/collection') ||
    pathname.startsWith('/search') ||
    // note the trailing / on /wishlist/ to avoid matching the main /wishlist page
    pathname.startsWith('/wishlist/');

  if (!showCategories) return null;

  // it will always use tall categories on tablet, but we want to force it on desktop on the home page
  const forceTallCategoriesOnDesktop = pathname === '/';

  if (isStockXSectionEnabled) {
    return (
      <Collapser>
        <Stack
          direction="row"
          justifyContent="center"
          pt={Spacing.small}
          pb={Spacing.base}
          px={{ smallTablet: 4.5, desktop: 2 }}
        >
          <Stack
            direction="row"
            flexGrow={1}
            maxWidth={{
              smallTablet: 'unset',
              desktop: forceTallCategoriesOnDesktop ? '1016px' : '1250px',
            }}
            justifyContent="space-between"
          >
            <CategoryList
              isStockXSectionEnabled={true}
              forceTallCategoriesOnDesktop={forceTallCategoriesOnDesktop}
            />
          </Stack>
        </Stack>
      </Collapser>
    );
  }

  return (
    <Collapser>
      <Stack
        direction="row"
        justifyContent="center"
        pt={Spacing.small}
        pb={Spacing.base}
        px={{ smallTablet: 10, desktop: 'unset' }}
        minWidth={{ smallTablet: '100vw', desktop: 'unset' }}
      >
        <Stack
          direction="row"
          gap={forceTallCategoriesOnDesktop ? Spacing.large : Spacing.xxlarge}
          flexGrow={{
            smallTablet: 0,
            desktop: 1,
          }}
          maxWidth={{
            smallTablet: 'unset',
            desktop: 900,
          }}
          justifyContent="space-between"
        >
          <CategoryList
            isStockXSectionEnabled={false}
            forceTallCategoriesOnDesktop={forceTallCategoriesOnDesktop}
          />
        </Stack>
      </Stack>
    </Collapser>
  );
};

export default Categories;
