'use client';

import { FC, use } from 'react';
import { Spacing } from '@ghosts-inc/design-system/constants';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { usePathname } from 'next/navigation';
import { ArrearsContext } from '@/components/ArrearsBanner/ArrearsContext';
import Link from '@/components/Link';
import Collapser from './Collapser';

export const WishlistBanner: FC = () => {
  const isBelowDesktop = useMediaQuery((th: Theme) => th.breakpoints.down('desktop'));
  const isBelowSmallTablet = useMediaQuery((th: Theme) => th.breakpoints.down('smallTablet'));

  const { showArrearsBanner } = use(ArrearsContext);

  const path = usePathname();

  const showWishlistBanner = path === '/';

  if (!showWishlistBanner || showArrearsBanner) return null;

  return (
    <Collapser>
      <Stack
        alignItems="center"
        bgcolor={(th) => th.palette.common.black}
        color={(th) => th.palette.common.white}
        py={Spacing.base}
      >
        <Typography color="typographyDarkInverted">
          {isBelowSmallTablet && (
            <>
              Add to your{' '}
              <Link color="typographyBrandPrimaryLight" href="/wishlist">
                Wishlist
              </Link>{' '}
              🪄
            </>
          )}
          {!isBelowSmallTablet && (
            <>
              <Link color="typographyBrandPrimaryLight" href="/wishlist">
                Introducing Wishlist 🪄:
              </Link>{' '}
              {isBelowDesktop ? (
                <>Add brands for us to source for you.</>
              ) : (
                <>
                  Add brands, categories, and budget to your wishlist, and get notified as we source
                  them for you.
                </>
              )}
            </>
          )}
        </Typography>
      </Stack>
    </Collapser>
  );
};
