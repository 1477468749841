'use client';

import { FC, PropsWithChildren } from 'react';
import { Duration, Easing } from '@ghosts-inc/design-system/constants';
import Collapse from '@mui/material/Collapse';
import useScrollTrigger from '@mui/material/useScrollTrigger';

const isAtBottom = () => {
  // scrollY = 0 when at the top
  // innerHeight = height of the browser window
  // scrollY + innerHeight = height of the browser window from the top to the bottom
  // document.body.offsetHeight = height of the entire document
  return typeof window === 'undefined' || window.scrollY === 0
    ? false
    : window.innerHeight + window.scrollY >= document.body.offsetHeight;
};

const Collapser: FC<PropsWithChildren> = ({ children }) => {
  const trigger = useScrollTrigger();

  // While being at the bottom of the page, useScrollTrigger triggers an infinite loop
  // where the Collapse will be shown and hidden repeatedly. Using isAtBottom() to prevent this.
  return (
    <Collapse in={isAtBottom() ? false : !trigger} easing={Easing.slow} timeout={Duration.t3}>
      {children}
    </Collapse>
  );
};

export default Collapser;
