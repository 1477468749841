import { User } from '@clerk/nextjs/server';
import { UserResource } from '@clerk/types';
import { Role } from '@/types/Role';

const getRoles = (user: UserResource | User): Role[] => {
  if (!user.publicMetadata.roles || !Array.isArray(user.publicMetadata.roles)) {
    return [];
  }

  return user.publicMetadata.roles as Role[];
};

export default getRoles;
