'use client';

import { PropsWithChildren } from 'react';
import { Watermark as ReactWatermark } from '@hirohe/react-watermark';
import Box from '@mui/material/Box';
import zIndex from '@mui/material/styles/zIndex';
import { UserSummary } from '@/state/types';
import { getFullUserName } from '../legacy/users/userSummary.model';

interface Props {
  user: UserSummary | null;
}

/**
 * Adds a watermark to the page, which is meant to help us identify buyers that share inventory screenshots with the public. There are actually 2 separate watermarks:
 * 1. A visible watermark that the user can barely see. This is meant to deter would-be screenshot-sharers away from sharing price information to the public.
 * 2. A "hidden" watermark that is nigh-imperceptible to the naked eye. This is meant to be used in case the visible watermark is removed or obscured and a screenshot is shared. In order to see this watermark, we must paste the screenshot into Figma and adjust the pasted image's "exposure" setting.
 */
export const Watermark = ({ children, user }: PropsWithChildren<Props>) =>
  !user ? (
    children
  ) : (
    <ReactWatermark
      // This is the "visible" watermark that's meant to be seen by the user and hopefully is enough of a deterrence against would-be screenshotters.
      textColor="#E4758B"
      opacity={0.085}
      gutter={30}
      text={getFullUserName(user)}
      textSize={14}
      wrapperStyle={{
        pointerEvents: 'none',
      }}
      zIndex={zIndex.tooltip + 100}
    >
      <ReactWatermark
        // This is a "hidden" watermark that's meant to be near-imperceptible to the human eye. Requires you to change the "exposure" of the screenshot in Figma.
        textColor="#E4758B"
        opacity={0.018}
        gutter={43}
        text={String(user.id)}
        textSize={14}
        rotate={45}
      >
        <Box
          style={{
            pointerEvents: 'auto',
          }}
        >
          {children}
        </Box>
      </ReactWatermark>
    </ReactWatermark>
  );
