'use client';

import { ReactNode, createContext, use } from 'react';
import { SearchClient } from 'algoliasearch/lite';
import TypesenseInstantSearchAdapter, {
  TypesenseInstantsearchAdapterOptions,
} from 'typesense-instantsearch-adapter';

const SearchClientContext = createContext<{
  searchClientPromise: Promise<SearchClient>;
}>({
  // this is only used if trying to use the context outside the provider, which should not happen
  searchClientPromise: new Promise(() => {}),
});

export const useSearchClient = () => {
  return use(SearchClientContext);
};

export const SearchClientProvider = ({
  searchKey,
  url,
  children,
}: {
  searchKey: string;
  url: string;
  children: ReactNode;
}): JSX.Element => {
  const getSearchClient = async () => {
    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
      server: {
        apiKey: searchKey, // Be sure to use an API key that only allows search operations
        nodes: [{ url: url }],
        cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
      },
      // The following parameters are directly passed to Typesense's search API endpoint.
      //  query_by is required according to the type definition
      additionalSearchParameters: {
        query_by: 'brand', // Required field
      },
      // You can also use collection-specific parameters if needed
      // collectionSpecificSearchParameters: {
      //   buyer_items: {
      //     query_by: 'brand',
      //   },
      // },
    } as TypesenseInstantsearchAdapterOptions);
    return typesenseInstantsearchAdapter.searchClient;
  };

  const searchClientPromise = getSearchClient();

  return (
    <SearchClientContext.Provider value={{ searchClientPromise }}>
      {children}
    </SearchClientContext.Provider>
  );
};
