import { Radius } from '@ghosts-inc/design-system/constants';
import { Close } from '@ghosts-inc/design-system/icons';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';

interface Props {
  onClose: () => void;
  open: boolean;
  url: string;
}

export const LineSheetTutorialVideo = ({ onClose, open, url }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        bgcolor: 'rgba(0, 0, 0, 0.5)',
        '& .MuiDialog-paper': {
          p: 0,
          borderRadius: { mobile: Radius.base, tablet: '24px' },
          overflow: 'hidden',
          maxWidth: 800,
          width: '100%',
        },
      }}
    >
      <DialogContent sx={{ p: 0, mt: 0 }}>
        <video width="100%" height="auto" autoPlay controls controlsList="nodownload">
          <source src={url} type="video/mp4" />
        </video>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={onClose} aria-label="close">
            <Close htmlColor="white" />
          </IconButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
