import { FC, HTMLAttributeAnchorTarget, PropsWithChildren } from 'react';
import MuiMenuItem from '@mui/material/MenuItem';
import Link from 'next/link';

export type MenuItemProps = { onClick?: () => void } & (
  | {
      href: string;
      target?: HTMLAttributeAnchorTarget;
    }
  | {
      href?: undefined;
      target?: undefined;
    }
);

const MenuItem: FC<PropsWithChildren<MenuItemProps>> = ({ href, onClick, children, target }) => {
  return href ? (
    <MuiMenuItem component={Link} href={href} target={target} onClick={onClick}>
      {children}
    </MuiMenuItem>
  ) : (
    <MuiMenuItem onClick={onClick}>{children}</MuiMenuItem>
  );
};

export default MenuItem;
