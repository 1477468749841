'use client';

import { ReactNode, createContext, use } from 'react';
import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import { SecuredKey } from '@/lib/algolia/actions';

const SearchClientContext = createContext<{
  searchClientPromise: Promise<SearchClient>;
}>({
  // this is only used if trying to use the context outside the provider, which should not happen
  searchClientPromise: new Promise(() => {}),
});
export const useSearchClient = () => {
  return use(SearchClientContext);
};

export const SearchClientProvider = ({
  children,
  keyPromise,
}: {
  children: ReactNode;
  keyPromise: Promise<SecuredKey>;
}): JSX.Element => {
  const getSearchClient = async () => {
    const { appId, securedKey } = await keyPromise;
    return algoliasearch(appId, securedKey);
  };

  const searchClientPromise = getSearchClient();

  return (
    <SearchClientContext.Provider value={{ searchClientPromise }}>
      {children}
    </SearchClientContext.Provider>
  );
};
