'use client';

import {
  FC,
  HTMLAttributeAnchorTarget,
  PropsWithChildren,
  use,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Sizes, Spacing } from '@ghosts-inc/design-system/constants';
import { Close, DotFilled, Hamburger } from '@ghosts-inc/design-system/icons';
import { GhostLogoV2EqualHeight } from '@ghosts-inc/design-system/svgs';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MuiLink from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import { LinkProps as NextLinkProps } from 'next/link';
import { usePathname } from 'next/navigation';
import { AnalyticsEventNames } from '@/analytics/Event.types';
import { analytics } from '@/analytics/segment';
import { InboxButtonContext } from '@/app/(authenticated)/(marketplace)/HeaderNew/_components/inboxButtonContext';
import { DisplayedMyDealCategory } from '@/components/Deals/types';
import Link from '@/components/Link';
import { isStockXSectionEnabled } from '@/launchdarkly/actions';
import { ReferrerStates } from '@/legacy/referrers/referrerSummary.model';
import { SellerStates } from '@/legacy/sellers/sellerSummary.model';
import { useGhostStore } from '@/legacy/store';
import useLogOut from '@/lib/clerk/useLogOut';
import { CategoryGroup, Collection } from '@/types/collections';
import { getActionRequiredCountForBuyer } from '../actions';
import { BuyerDealsList, DEFAULT_DEALS_COUNT } from '../types';
import { CATEGORIES } from './constants';

interface DrawerMenuProps {
  clerkEnabled: boolean;
  isWishlistEnabled: boolean;
}

const MenuLink: FC<
  PropsWithChildren<
    Pick<NextLinkProps, 'href' | 'onClick'> & {
      target?: HTMLAttributeAnchorTarget;
    }
  >
> = ({ children, href, onClick, target }) => {
  return (
    <Link
      mx={Spacing.small}
      my={Spacing.small12}
      href={href}
      onClick={onClick}
      target={target}
      color="typographyDarkPrimary"
    >
      {children}
    </Link>
  );
};

const DrawerMenu: FC<DrawerMenuProps> = ({ clerkEnabled, isWishlistEnabled }) => {
  const [open, setOpen] = useState(false);
  const { hasUnreadMessages, refresh: refreshInbox } = use(InboxButtonContext);
  const [buyerDealsList, setBuyerDealsList] = useState<BuyerDealsList>(DEFAULT_DEALS_COUNT);
  const [isStockXEnabled, setIsStockXEnabled] = useState(false);

  const refreshBuyerDealList = useCallback(async () => {
    const updatedList = await getActionRequiredCountForBuyer();
    setBuyerDealsList(updatedList);
  }, []);

  useEffect(() => {
    isStockXSectionEnabled().then(setIsStockXEnabled);
  }, []);

  const pathname = usePathname();
  useEffect(() => {
    refreshInbox();
    refreshBuyerDealList();
  }, [pathname, refreshInbox, refreshBuyerDealList]);

  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const { user } = useGhostStore();
  const { logOut } = useLogOut(clerkEnabled);

  const handleLinkClick = ({
    categoryGroup,
    collection,
    index,
    label,
  }: {
    categoryGroup?: CategoryGroup;
    collection: Collection;
    index: number;
    label: string;
  }) => {
    analytics.track(AnalyticsEventNames.NAV_LINK_CLICKED, {
      collection: collection,
      category_group: categoryGroup,
      display_text: label,
      index,
    });
    closeDrawer();
  };

  const handleLogout = async () => {
    closeDrawer();
    await logOut();
  };

  const isSeller = user?.seller?.state === SellerStates.ACTIVATED;
  const isReferrer = user?.referrer?.state === ReferrerStates.ACTIVATED;
  const hasActionRequired = buyerDealsList?.[DisplayedMyDealCategory.ALL_DEALS] > 0;

  const filteredCategories = isStockXEnabled
    ? CATEGORIES
    : CATEGORIES.filter(({ label }) => label !== 'StockX');

  return (
    <>
      <IconButton onClick={openDrawer}>
        <Hamburger data-testid="drawer-menu-icon" />
      </IconButton>
      <SwipeableDrawer anchor="right" open={open} onOpen={openDrawer} onClose={closeDrawer}>
        <Stack width="100vw" data-testid="drawer-menu-contents">
          <Stack
            direction="row"
            justifyContent="space-between"
            p={Spacing.base}
            borderBottom={(theme) => `solid 1px ${theme.palette.lightBorder.default}`}
          >
            <IconButton href="/" onClick={closeDrawer}>
              <GhostLogoV2EqualHeight height={Sizes.small} data-testid="drawer-menu-home" />
            </IconButton>
            <IconButton onClick={closeDrawer}>
              <Close data-testid="drawer-menu-close-icon" />
            </IconButton>
          </Stack>
          <Stack px={Spacing.base} py={Spacing.large}>
            <MenuLink href="/" onClick={closeDrawer}>
              Home
            </MenuLink>
            <MenuLink href="/inbox" onClick={closeDrawer}>
              Inbox
              {hasUnreadMessages && (
                <DotFilled
                  data-testid="inbox-unread-dot"
                  sx={(theme) => ({
                    width: 8,
                    marginLeft: Spacing.small,
                    color: theme.palette.brandPrimaryFill.default,
                  })}
                />
              )}
            </MenuLink>
            {!isWishlistEnabled && (
              <MenuLink href="/saved" onClick={closeDrawer}>
                Saved
              </MenuLink>
            )}
            {isWishlistEnabled && (
              <MenuLink href="/wishlist" onClick={closeDrawer}>
                Wishlist
              </MenuLink>
            )}
            <MenuLink href="/offers" onClick={closeDrawer}>
              My Deals{' '}
              {hasActionRequired && (
                <DotFilled
                  data-testid="needs-review-icon"
                  sx={(theme) => ({
                    width: 8,
                    marginLeft: Spacing.small,
                    color: theme.palette.brandPrimaryFill.default,
                  })}
                />
              )}
            </MenuLink>
            <Stack my={Spacing.small12}>
              <Divider />
            </Stack>

            {filteredCategories.map(({ label, href, collection, categoryGroup, icon }, index) => (
              <Link
                href={href}
                key={label}
                onClick={() => handleLinkClick({ index, label, collection, categoryGroup })}
                color="typographyDarkPrimary"
              >
                <Stack
                  alignItems="center"
                  direction="row"
                  gap={Spacing.small}
                  px={Spacing.small}
                  py={Spacing.small12}
                >
                  {icon({
                    sx: {
                      width: Sizes.xsmall,
                      height: Sizes.xsmall,
                    },
                    htmlColor: 'black',
                  })}
                  <Typography>{label}</Typography>
                </Stack>
              </Link>
            ))}
            <Stack my={Spacing.small12}>
              <Divider />
            </Stack>
            <MenuLink href="/preferences" onClick={closeDrawer}>
              Preferences
            </MenuLink>
            {isSeller && (
              <MenuLink href="/seller" target="_blank" onClick={closeDrawer}>
                Switch to Seller
              </MenuLink>
            )}
            {isReferrer && (
              <MenuLink href="/referrer" target="_blank" onClick={closeDrawer}>
                Switch to Referrer
              </MenuLink>
            )}
            <MuiLink
              mx={Spacing.small}
              my={Spacing.small12}
              onClick={handleLogout}
              color="typographyDarkPrimary"
            >
              Sign Out
            </MuiLink>
          </Stack>
        </Stack>
      </SwipeableDrawer>
    </>
  );
};

export default DrawerMenu;
