export enum DisplayedMyDealStatuses {
  DRAFT = 'draft',
  IN_REVIEW = 'in_review',
  COUNTERED = 'countered',
  ACCEPTED = 'accepted',
  ARCHIVED = 'archived',
  PAYMENT_DUE = 'payment_due',
  PACKING = 'packing',
  READY_FOR_PICKUP = 'ready_for_pickup',
  SHIPPED = 'shipped',
  DELIVERED = 'delivered',
  CANCELED = 'canceled',
  WITHDRAWN = 'withdrawn',
  DECLINED = 'declined',
  NEW = 'new',
  AWAITING_PICKUP = 'awaiting_pickup',
}

export enum DisplayedMyDealCategory {
  ALL_DEALS = 'all_deals',
  OFFERS = 'offers',
  ORDERS = 'orders',
  ARCHIVED = 'archived',
}
