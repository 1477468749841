export enum DisplayedDealStatusForBuyer {
  // latest offer direction is ghost to buyer
  NEEDS_REVIEW = 'needs_review',
  // latest offer direction is buyer to ghost
  PENDING = 'pending',
  DRAFT = 'draft',
  // deal status is accepted
  COMPLETED = 'completed',
  // deal status is declined or withdrawn
  ARCHIVED = 'archived',
}

export const STATUS_TO_DISPLAYED_TEXT_FOR_BUYER: Record<DisplayedDealStatusForBuyer, string> = {
  [DisplayedDealStatusForBuyer.NEEDS_REVIEW]: 'Action Required',
  [DisplayedDealStatusForBuyer.PENDING]: 'Pending',
  [DisplayedDealStatusForBuyer.DRAFT]: 'Draft',
  [DisplayedDealStatusForBuyer.COMPLETED]: 'Completed',
  [DisplayedDealStatusForBuyer.ARCHIVED]: 'Archived',
};

export type MenuItem = {
  value: DisplayedDealStatusForBuyer;
  label: string;
  count?: number;
};
