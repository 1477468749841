import { useClerk } from '@clerk/nextjs';
import { AnalyticsEventNames } from '@/analytics/Event.types';
import { analytics } from '@/analytics/segment';
import { handleSignOut } from '@/components/UserMenu/actions';
import { useGhostStore } from '@/legacy/store';

export default function useLogOut(clerkEnabled: boolean) {
  const { signOut } = useClerk();
  const { setUser } = useGhostStore();

  const logOut = async () => {
    analytics.track(AnalyticsEventNames.LOGOUT_CLICKED);

    if (clerkEnabled) {
      await signOut();
    } else {
      await handleSignOut();
    }

    setUser(null);
  };

  return { logOut };
}
