'use client';

import { ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { ConStrainer } from '@/components/ConStrainer';
import { Background } from './types';

type LayoutProps = Omit<BoxProps, 'bgcolor'>;

export default function Section({
  background,
  children,
  paddingX,
  ...rest
}: {
  background?: Background;
  children: ReactNode;
} & LayoutProps): JSX.Element {
  const theme = useTheme();

  const bgcolor =
    background === 'dark'
      ? theme.palette.common.black
      : background === 'light'
        ? theme.palette.surface.lighter
        : 'transparent';

  return (
    <Box
      paddingX={paddingX ? paddingX : { mobile: 2, smallTablet: 3, tablet: 10 }}
      bgcolor={bgcolor}
      {...rest}
    >
      <ConStrainer outerContainerProps={{ paddingY: { mobile: 3, smallTablet: 5, desktop: 7 } }}>
        {children}
      </ConStrainer>
    </Box>
  );
}
