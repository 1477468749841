'use client';

import { FC } from 'react';
import { GhostLogoV2 } from '@ghosts-inc/design-system/svgs';
import Button from '@mui/material/Button';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import InboxButton from './InboxButton';

type HighlightedButton = 'logo' | 'inbox' | 'favorites' | 'wishlist';

type NavButtonsProps = {
  isWishlistEnabled: boolean;
};

const determineHighlight = (pathname: string, isWishlistEnabled: boolean): HighlightedButton => {
  if (pathname.startsWith('/saved')) {
    return 'favorites';
  }
  if (pathname.startsWith('/inbox')) {
    return 'inbox';
  }
  if (pathname.startsWith('/wishlist') && isWishlistEnabled) {
    return 'wishlist';
  }
  return 'logo';
};

const determineVariant = (highlight: boolean) => {
  return highlight ? 'contained' : 'text';
};

const NavButtons: FC<NavButtonsProps> = ({ isWishlistEnabled }) => {
  const pathname = usePathname();

  const highlight = determineHighlight(pathname, isWishlistEnabled);

  return (
    <>
      <Button
        LinkComponent={Link}
        size="headerLogo"
        href="/"
        variant={determineVariant(highlight === 'logo')}
        data-testid="logo-button"
      >
        <GhostLogoV2 width={75} />
      </Button>
      <InboxButton size="headerLink" variant={determineVariant(highlight === 'inbox')} />
      {!isWishlistEnabled && (
        <Button
          LinkComponent={Link}
          size="headerLink"
          href="/saved"
          variant={determineVariant(highlight === 'favorites')}
        >
          Saved
        </Button>
      )}
      {isWishlistEnabled && (
        <Button
          LinkComponent={Link}
          size="headerLink"
          href="/wishlist"
          variant={determineVariant(highlight === 'wishlist')}
        >
          Wishlist
        </Button>
      )}
    </>
  );
};

export default NavButtons;
